import React from 'react';

import GetPolitics from '../components/GetPolitics';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';

const Politics = () => {
  return (
    <>
      <Navbar />
      <GetPolitics />
      <Footer />
    </>
  )
}

export default Politics