import React from 'react';
import { Link } from 'react-router-dom';

import {
  Typography,
} from '@mui/material';

import Breadcrumb from '../components/Breadcrumb';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';

//import Carousel from '../components/Carousel';
import PropertyDetailComponent from '../components/PropertyDetail';

const PropertyDetail = () => {

  return (
    <>
      <Navbar />
      <Breadcrumb divider={false}>
        <Typography component={Link} to="/" variant="h4" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography component={Link} to="/property" variant="h4" color="inherit" className="link-breadcrumb">
          Propiedades
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Propiedad Detalle
        </Typography>
      </Breadcrumb>
      <PropertyDetailComponent />
{/*      <Carousel page={"PropertyDetail"} /> */}
      <Footer />
    </>
  )
}

export default PropertyDetail