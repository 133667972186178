import React from 'react'
import Details from '../components/Details';
import GetMeta from '../components/GetMeta';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';

const Contact = () => {
  return (
    <>
      <Navbar />
      <Details />
      <Footer />
      <GetMeta page={6} />
    </>
  )
}

export default Contact