import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Snackbar,
  Typography
} from '@mui/material';

import { gridSpacing } from '../../../config';
import { useTheme } from '@mui/material/styles';

import SplitButton from '../../SplitButton';

import { useDispatch } from 'react-redux';
import * as actionTypes from '../../../store/actions';

/* LLAMARDAS DE SERVICIOS API */
import SiteService from '../../../services/SiteService';

export default function SearchByCity() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [isShowResults, setIsShowResults] = useState(false);

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [alerta, setAlerta] = React.useState({
    tipo: 'error',
    mensaje: ''
  });

  /* VARIABLES GENERALES DEL FORMULARIO */

  const [countryID, setCountryID] = useState(207);
  const [countries, setCountries] = useState([{id: 0, name: "(Países)", currency: '', currency_symbol: ''}]);
  const [citiesQuantity, setCitiesQuantity] = useState([]);
  const [gridQuantity, setGridQuantity] = useState([]);

  let values = {
    country_id: 0,
    state_id: 0,
    city_id: 0,
    neighborhood: '',
    material_id: 0,
    commercialoperation_id: 0,
    m3_min: 0,
    m3_max: 0,
    price_min: 0,
    price_max: 0,
    years_min: 0,
    years_max: 0
  };

  const onSubmitData = () => {

    dispatch({ type: actionTypes.SET_SEARCH, search: {
      country_id: values.country_id,
      state_id: values.state_id,
      city_id: values.city_id,
      neighborhood: '',
      material_id: 0,
      commercialoperation_id: 0,
      m3_min: 0,
      m3_max: 0,
      price_min: 0,
      price_max: 0,
      years_min: 0,
      years_max: 0
    } });

    try {
      SiteService.OPERATIONLOG_Register({
        type: "critery",
        action: "searchByCity",
        country_id: values.country_id,
        state_id: values.state_id,
        city_id: values.city_id,
        neighborhood: "",
        material_id: 0,
        total_mts3_min: 0,
        total_mts3_max: 0,
        construction_min: 0,
        construction_max: 0,
        commercialoperation_id: 0,
        price_min: 0,
        price_max: 0      
      });
    } catch (err) {
      const errorMessage = "Error: " + err.message;
      console.log(errorMessage);
    }


    setIsShowResults(true);

  }

  const handleClose = () => {
    setOpen(false);
  };

  const handeChangeListCity = (state_id, city_id) => {
    /*
    console.log("state_id **>> ", state_id);
    console.log("city_id **>> ", city_id);
    */

    values.country_id = countryID;
    values.state_id = state_id;
    values.city_id = city_id;

    onSubmitData();
  }

  const handleSplitButtonChange = (event, country_id) => {
    setCountryID(country_id);
  };

  const COUNTRY_List = async () => {
    try {
      const response = await SiteService.COUNTRY_List("Seleccionar el País");
      setCountries(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }

  }

  const PROPERTY_countByCountryID = async () => {


    //console.log("countryID ===>>> ", countryID);
    try {
      const response = await SiteService.PROPERTY_countByCountryID(countryID);

      setCitiesQuantity(response.datos.data);

    } catch (err) {
      const errorMessage = "Error: " + err.message;
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      setAlerta({
        tipo: "error",
        mensaje: "Error: " + err.message
      })
      console.log(errorMessage);
    }

  }

  useEffect(() => {
    if (countryID > 0) {
      PROPERTY_countByCountryID();
    } else {
      setCitiesQuantity([]);
    }
  }, [countryID]);

  useEffect(() => {
    let count = -1;
    let state_id = 0;
    let temp = [];
/*
    console.log("**************************************************");
    console.log("citiesQuantity ==>> ", citiesQuantity);
    console.log("**************************************************");
        temp[count].cities.push({
*/
    citiesQuantity.map(item => {
      if (state_id == item.state_id) {
        temp[count].cities.push({
          state_id: item.state_id,
          city_id: item.city_id,
          city_name: item.city_name,
          quantity: item.quantity,
        });
      } else {
        temp.push({
          state_id: item.state_id,
          state_name: item.state_name,
          cities: [{
            state_id: item.state_id,
            city_id: item.city_id,
            city_name: item.city_name,
            quantity: item.quantity,
          }]
        });
        count++;
        state_id = item.state_id;
      }
    })
    setGridQuantity(temp);

    /*
    console.log("**************************************************");
    console.log("temp         ==>> ", temp);
    console.log("gridQuantity ==>> ", gridQuantity);
    console.log("**************************************************");
    */
  }, [citiesQuantity])

  /* SE EJECTURA UNA VEZ AL INGRESAR */
  useEffect(() => {

    setCountries([
      {id: 0, name: "(Países)", currency: '', currency_symbol: ''},
    ]);

    COUNTRY_List();

    setIsLoading(false);
/*
    console.log("***********************************************************");
    console.log("Horario              => ", new Date());
    console.log("commercialOperations => ", commercialOperations);
    console.log("***********************************************************");
*/
  }, []);

  if (isShowResults) {
    return  <Navigate to="/property" />
  }
  
  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
            <Card 
              sx={{ width: 'auto', 
                height: '100%', 
                // color de fondo
                backgroundColor: 'white',
              }}
              variant='outlined'
            >
              <CardHeader />
              <CardContent sx={{ width: 'auto', height: '100%' }}>
                <Typography
                  variant="h4"
                  component="h5"
                  align='center'
                  sx={{
                    width: '100%',
                    borderRadius: 20,
                    bgcolor: 'terciary.main',
                    color:'white',
                    mt: theme.spacing(2), mb: theme.spacing(2),
                    pl: theme.spacing(2), pr: theme.spacing(2)
                  }}
                >
                  BUSCA POR CIUDAD
                </Typography>

                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}
                      sx={{ 
                        ml: theme.spacing(10), 
                        mr: theme.spacing(10),
                      }}
                  >
                    <SplitButton 
                      options={ countries }
                      onChange={ handleSplitButtonChange }
                    ></SplitButton>

                  </Grid>
                  <Grid item xs={12}
                      sx={{ 
                        ml: {
                          xs: theme.spacing(2),
                          sm: theme.spacing(20),
                        }, 
                        mr: {
                          xs: theme.spacing(2),
                          sm: theme.spacing(20),
                        }, 
                      }}
                  >
                    <Grid container>
                      {gridQuantity.map( dato => (
                        <Grid key={dato.state_id} item xs={12} sm={4}>
                          <Typography variant="h4">{ dato.state_name }</Typography>
                          <ListCity 
                              state_id={ dato.state_id}
                              cities={ dato.cities }
                              onChange={ handeChangeListCity } 
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
          anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}>
        <Alert
          onClose={handleClose}
          severity={alerta.tipo}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alerta.mensaje}
        </Alert>
      </Snackbar>

    </>
 );
}


function ListCity({ cities, onChange }) {

  const handleClick = (state_id, city_id) => {
    /*
    console.log("state_id => ", state_id);
    console.log("city_id => ", city_id);
    */
    onChange(state_id, city_id);
  }

  return (
    <Grid container>
      {cities.map( cit => (
        <Grid key={cit.city_id} item xs={12}>
          <Grid container>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={1}>
              <Typography>{ cit.quantity }</Typography>
            </Grid>
            <Grid item xs={10}>
              <Button variant="text"
                onClick={() => handleClick(cit.state_id, cit.city_id)}
              >
                { cit.city_name }
              </Button>
            </Grid>
          </Grid>
        </Grid>
        ))}
    </Grid>
  )
}
