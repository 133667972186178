import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom';
import { 
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material'
import Link from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import { gridSpacing } from '../../config';
import { useTheme } from '@mui/material/styles';

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../../services/SiteService';

import logo from '../../assets/images/buscatrasterologo.png';

const GetThankYou = () => {
  const theme = useTheme();
  const [parameters, setParameters] = useState([]);
  const [isHome, setIsHome] = useState(false);

  const PARAMETER_Get = async () => {
    try {
      const response = await SiteService.PARAMETER_Get();
      setParameters(response.datos.data);
    } catch (err) {
    const errorMessage = "Error: " + err.message;
    console.log(errorMessage);
    }
  };

  useEffect(() => {
    PARAMETER_Get();
  }, []);


  if (isHome) return  <Navigate to="/" />

  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sx={{ 
            mt: theme.spacing(10), 
            mb: theme.spacing(5), 
            ml: { 
              xs: theme.spacing(0), 
              md: theme.spacing(30)
            },
            mr: { 
              xs: theme.spacing(0), 
              md: theme.spacing(30)
            }
          }}
        >
            <Card 
              sx={{ 
                // color de fondo
                backgroundColor: 'rgba(109,66,155,0.3)'
              }}
              variant='outlined'
            >
            <CardHeader />
              <CardContent sx={{ width: 'auto', height: '100%' }}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Box mt={2} display="flex" justifyContent="center">
                      <img src={logo} alt="Logo" width="300px" height="300px"/>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={2}>
                  </Grid>
                  <Grid item xs={8}>
                    <Divider />
                  </Grid>
                  <Grid item xs={2}>
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Box mt={2} display="flex" justifyContent="center">
                      <Typography 
                        variant='h3'
                        component= 'h3'
                        justifyContent={"center"}
                        alignItems={'center'}
                      >
                        Muchas gracias
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Box mt={2} display="flex" justifyContent="center">
                      <Typography 
                        variant='h5'
                        component= 'h5'
                        justifyContent={"center"}
                        alignItems={'center'}
                      >
                        ¡Hemos recibido tu solicitud!
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <Box mt={2} display="flex" justifyContent="center">
                      <Typography 
                        variant='h5'
                        component= 'h5'
                        justifyContent={"center"}
                        alignItems={'center'}
                      >
                        No te olvides de seguirnos en nuestras Redes Sociales.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={3}>
                  </Grid>
                  <Grid item xs={3}>
                    <Box mt={2} display="flex" justifyContent="center">
                      <Link href={ parameters.instagram } target="_blank" variant="h1" 
                        sx={{
                          color: '#414141',
                          "&:hover": {
                            color: '#1c2859',
                          }
                        }}
                        >
                          <InstagramIcon fontSize="inherit"/>  
                        </Link> 
                      </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box mt={2} display="flex" justifyContent="center">
                      <Link href={ parameters.facebook } target="_blank" variant="h1" 
                        sx={{
                          color: '#414141',
                          "&:hover": {
                            color: '#1c2859',
                          }
                        }}
                        >
                          <FacebookIcon fontSize="inherit"/>
                      </Link> 
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mt={2} display="flex" justifyContent="center">
                      <Tooltip title="Buscar">
                          <Button 
                            aria-label="buscar" 
                            type="submit" 
                            variant="contained" 
                            color="terciary" 
                            size="large"
                            onClick={ () => { setIsHome(true) } }
                        >
                          <b>IR AL HOME</b>
                        </Button>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

        </Grid>
      </Grid>
    </>

  )
}

export default GetThankYou



/*


    <BoxRow 
    component = 'footer'
    sx={{
      py: 4,
      px: 2,
    }}
    >
      <StackColumn>
        <FooterTitle text={'Dirección'} />
        <FooterLink 
        text={ parameters.address1 } 
        />
        <FooterLink 
        text={ parameters.address2 } 
        />
        <FooterLink 
        text={ parameters.phones } 
        />
        <FooterLink 
        text={ parameters.email } 
        />
      </StackColumn>
      
      <StackColumn>
        <FooterTitle text={'Accesos'} />
        <FooterLink text={'Contáctenos'} direction="/contact"/>
        <FooterLink text={'Preguntas'} direction="/faq"/>
        <FooterLink text={'Políticas'} direction="/politics"/>
        <FooterLink text={'Cookies'} direction="/cookies"/>
      </StackColumn>

      <StackColumn>
        <FooterTitle text={'Busca Trastero'} />
        <Stack 
        direction='row' 
        width= '70px'
        maxWidth='100%'
        justifyContent='space-between'
        >
          <Link href={ parameters.instagram } target="_blank" variant="body2" 
          sx={{
            color: '#414141',
            "&:hover": {
              color: '#1c2859',
            }
          }}
          >
            <InstagramIcon fontSize="large"/>  
          </Link> 
          <Link href={ parameters.facebook } target="_blank" variant="body2" 
          sx={{
            color: '#414141',
            "&:hover": {
              color: '#1c2859',
            }
          }}
          >
            <FacebookIcon fontSize="large"/>
          </Link> 
        </Stack>
        <Typography 
        variant='caption'
        component='p' 
        >
          &copy; 2024 Busca Trastero.
        </Typography>
      </StackColumn>

      <StackColumn>
        <Box>
            <img src={logo} alt="Logo" width="250px" height="250px"/>
        </Box>
      </StackColumn>

    </BoxRow>
*/