import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Cookie from 'js-cookie';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardMedia,
  CardContent,
  Divider,
  Dialog,
  Grid,
  Typography
} from '@mui/material';

import phone from '../assets/images/phone.svg';
import whatsapp from '../assets/images/whatsapp.svg';
import email from '../assets/images/email.svg';

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';

import GetRegister from './Forms/Register/GetRegister';
import SendPhoneForm from './Forms/SendPhone';
import SendWhatsAppForm from './Forms/SendWhatsApp';
import SendMailForm from './Forms/SendMail';

import { useDispatch } from 'react-redux';
import * as actionTypes from '../store/actions';

import { store } from '../store';

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../services/SiteService';

export default function PropertyCard(props) {
  const theme = useTheme();
  const [isViewMore, setIsViewMore] = useState(false);
  const [parameters, setParameters] = useState(store.getState().parameters);
  
  const [openRegister, setOpenRegister] = useState(false);
  const [media, setMedia] = useState(0);
  const [openPhone, setOpenPhone] = useState(false);
  const [openWhatsApp, setOpenWhatsApp] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [user__CK, setUser__CK] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: ""
  })
  /*
    0: Phone
    1: Whatsapp
    2: Email
  */

  const dispatch = useDispatch();

  const handleRegisterClick = (media) => {

    setMedia(media);
    if ( !(Cookie.get("__CK_registered") === undefined) && Cookie.get("__CK_registered") ) {
      // HAY COOKIE CREADA Y ES VERDADERA

      switch (media) {
        case 0: // Phone
          setOpenPhone(true);
          break;

        case 1: //Whatsapp
          setOpenWhatsApp(true);
          break;

        case 2: // Email
          setOpenMail(true);
          break;
        
        default:
      }
    } else {
      setOpenRegister(true);
    }
  };

  const handleRegisterOnClose = (validated, user) => {
    setOpenRegister(!validated) 

    setUser__CK({
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone
    })    


    /*
    console.log("*****************************************");
    console.log("validated => ", validated);
    console.log("*****************************************");
    */

    if (validated) {
      switch (media) {
        case 0: // Phone
          setOpenPhone(true);
          break;

        case 1: //Whatsapp
          setOpenWhatsApp(true);
          break;

        case 2: // Email
          setOpenMail(true);
          break;
        
        default:
      }
    }
  }

  const handleAccessViewMoreClick = () => {
    dispatch({ type: actionTypes.SET_PROPERTYID, propertyID: props.property.id });

    try {
      SiteService.OPERATIONLOG_Register({
          type: "property",
          action: "view",
          associated_id: props.property.id,
          country_id: props.property.country_id,
          state_id: props.property.state_id,
          city_id: props.property.city_id,
          neighborhood: props.property.neighborhood,
          material_id: 0,
          total_mts3_max: 0,
          construction_min: 0,
          commercialoperation_id: 0,
          price_max: 0,
          object: JSON.stringify( props )
      });
  } catch (err) {
      const errorMessage = "Error: " + err.message;
      console.log(errorMessage);
  }

    setIsViewMore(true);
  };

  const handleOnCloseSendMailForm = () => {
    setOpenMail(false);
  }

  const handleOnCloseSendwhatsAppForm = () => {
    setOpenWhatsApp(false);
  }
  const handleOnCloseSendPhoneForm = () => {
    setOpenPhone(false);
  }


  useEffect( () => {

    if ( !(Cookie.get("__CK_registered") === undefined) && Cookie.get("__CK_registered") ) {
      // HAY COOKIE CREADA Y ES VERDADERA
      if (Cookie.get("__CK_user") === "undefined") {
        setUser__CK({
          email: "",
          first_name: "",
          last_name: "",
          phone: ""
        });
      } else {
        let tmpUser = JSON.parse(Cookie.get("__CK_user"));
        setUser__CK({
          email: tmpUser.email,
          first_name: tmpUser.first_name,
          last_name: tmpUser.last_name,
          phone: tmpUser.phone
        })
      }

    } else {
      setUser__CK({
        email: "",
        first_name: "",
        last_name: "",
        phone: ""
      });
    }
  });

  if (isViewMore) return  <Navigate to="/property/detail" />
  return (
    <>
      <Card 
        elevation={0} 
        sx={{ 
          width:"100%", 
          maxWidth: "100%",
        }}
      >
        <CardContent>
          <Grid container spacing={gridSpacing}>
              <Grid item sm={4} xs={12}>
                <CardMedia
                  sx={{ 
                    height: {
                      xs: '100px',
                      md: '250px'
                    },
                    width: '100%'
                  }}
                  image={ (props.property.image_predetermined.length > 0? parameters.parameters.httpConfig.backEndURL + 'storage/' + props.property.image_predetermined[0].path + props.property.image_predetermined[0].name: parameters.parameters.imageDefault) }
                  title={ (props.property.image_predetermined.length > 0? props.property.image_predetermined[0].name : "Image Default") }
                  onClick={ handleAccessViewMoreClick }
                >
                </CardMedia>
              </Grid> 
              <Grid item sm={8} xs={12}>
                  <Grid container
                    onClick={ handleAccessViewMoreClick }
                  >                  
                    <Grid item xs={12}>
                      <Typography variant='subtitle2' color='primary'> 
                      { "ID: " + props.property.id } | { props.property.address }{ ( props.property.neighborhood !== "" ? " (Barrio: " + props.property.neighborhood + ")": "") }, { props.property.city.name }, { props.property.state.name }, { props.property.country.name }  
                      </Typography > 
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                      <Typography variant="caption" color='black' fontWeight={300} fontSize={16}>{ props.property.commercialoperation.name }</Typography>
                      &nbsp;
                      <Typography variant="h5" color='black' fontWeight={700}>{ props.property.price }</Typography>
                      &nbsp;
                      <Typography variant="overline" color="black" fontWeight={700}>{ props.property.currency }</Typography>
                      </Grid> 
                    </Grid> 

                    <Grid item xs={12}>
                      <Grid container>
                        <Typography variant="caption" color='black' fontWeight={300} fontSize={16}>{ props.property.total_mts3 }</Typography>
                        &nbsp;
                        <Typography variant="caption" color="black" fontWeight={300}>mts<sup>2</sup></Typography>
                        &nbsp;
                        <Typography variant="caption" color="black" fontWeight={300} fontSize={16}>{ props.property.material.name }</Typography>
                      </Grid> 
                    </Grid> 

                    <Grid item xs={12}
                        sx={{ 
                          height: {
                            xs: '100%',
                            md: '110px'
                          },
                          width: '100%'
                        }}
                    >
                      <Grid container>
                        <Typography variant="body2" color='black'>{ (typeof props.property.description === "string" && props.property.description.length > 0) ? props.property.description.slice(0, 200) + "..." : "" }</Typography>
                      </Grid> 
                    </Grid> 

                  </Grid> 

                  <Divider />
                  <Grid container >
                    <Grid item xs={6}>
                      <Box mt={2}  display="flex" justifyContent="flex-start">
                      </Box>
                    </Grid> 
                    <Grid item xs={6}>
                      <Box mt={2}  display="flex" justifyContent="flex-end">
                        <ButtonGroup size="small" aria-label="button group" variant="text" color="primary">
                          <Button aria-label="Phone"    color="terciary" onClick={ () => handleRegisterClick(0) } ><img src={phone} height="35" width="35"/></Button>
                          <Button aria-label="WhatsApp" color="terciary" onClick={ () => handleRegisterClick(1) } ><img src={whatsapp} height="35" width="35"/></Button>
                          <Button aria-label="Email"    color="terciary" onClick={ () => handleRegisterClick(2) } ><img src={email} height="35" width="35"/> </Button>
                        </ButtonGroup>
                      </Box>
                    </Grid>
                  </Grid>
              </Grid> 
          </Grid>
        </CardContent>
      </Card>
      <GetRegister 
        open={ openRegister } 
        onClose={ handleRegisterOnClose }
      />
      <Dialog
        open={ openPhone }
        onClose={ (event, reason) => { if (reason !== 'backdropClick') { setOpenPhone(false) } } }
      >
        
        <Box sx={{ m: theme.spacing(4) }}>
          <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>{ user__CK.first_name } </Typography>          
                <Typography variant="subtitle1" gutterBottom color={"grey"}>¡Estás cada vez más cerca de conseguir tu Trastero!</Typography>          
                <Divider/>
                <SendPhoneForm data={{
                    email: user__CK.email,
                    first_name: user__CK.first_name,
                    last_name: user__CK.last_name,
                    phone: user__CK.phone,
                    subject: "[ID: " + props.property.id + " ] " + props.property.title + " - INTERÉS",
                    content: "¡Hola! Quiero que se comuniquen conmigo por este Trastero que vi en Buscatrastero.\n [ID: " + props.property.id + " ] " + props.property.title,
                    receiver: props.property.user.email,
                    user_first_name: props.property.user.first_name,
                    user_phone: props.property.user.phone,
                    object: props.property
                  }} 
                  onClose={ handleOnCloseSendPhoneForm }
                />
                <Divider/>
                <Typography variant="subtitle1" gutterBottom color={"grey"}>Te mostramos el teléfono y enviamos un mensaje para que te contacte el anunciante.</Typography>          
              </Grid>
          </Grid>
        </Box>
      </Dialog>

      <Dialog
        open={ openWhatsApp }
        onClose={ (event, reason) =>  { if (reason !== 'backdropClick') { setOpenWhatsApp(false) } } }
      >
        
        <Box sx={{ m: theme.spacing(4) }}>
          <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>{ user__CK.first_name } </Typography>          
                <Typography variant="subtitle1" gutterBottom color={"grey"}>¡Estás cada vez más cerca de conseguir tu Trastero!</Typography>          
                <Divider/>
                <SendWhatsAppForm data={{
                    email: user__CK.email,
                    first_name: user__CK.first_name,
                    last_name: user__CK.last_name,
                    phone: user__CK.phone,
                    subject: "[ID: " + props.property.id + " ] " + props.property.title + " - INTERÉS",
                    content: "¡Hola! Me estoy comunicando por WhatsApp por este Trastero que vi en Buscatrastero.\n [ID: " + props.property.id + " ] " + props.property.title,
                    receiver: props.property.user.email,
                    user_first_name: props.property.user.first_name,
                    user_phone: props.property.user.phone,
                    object: props.property
                  }} 
                  onClose={ handleOnCloseSendwhatsAppForm }
                />
                <Divider/>
                <Typography variant="subtitle1" gutterBottom color={"grey"}>Te mostramos el teléfono y enviamos un mensaje para que te contacte el anunciante.</Typography>          
              </Grid>
          </Grid>
        </Box>
      </Dialog>

      <Dialog
        open={ openMail }
        onClose={ (event, reason) =>  { if (reason !== 'backdropClick') { setOpenMail(false) } } }
      >
        
        <Box sx={{ m: theme.spacing(4) }}>
          <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>{ user__CK.first_name } </Typography>          
                <Typography variant="subtitle1" gutterBottom color={"grey"}>¡Estás cada vez más cerca de conseguir tu Trastero!</Typography>          
                <Divider/>
                <Typography align='center' variant="h6"display="block" gutterBottom color={"black"}>Tomá contacto con { props.property.user.first_name }</Typography>          
                <SendMailForm data={{
                    email: user__CK.email,
                    first_name: user__CK.first_name,
                    last_name: user__CK.last_name,
                    phone: user__CK.phone,
                    subject: "[ID: " + props.property.id + " ] " + props.property.title + " - INTERÉS",
                    content: "¡Hola! Quiero que se comuniquen conmigo por este Trastero que vi en Buscatrastero.\n [ID: " + props.property.id + " ] " + props.property.title,
                    receiver: props.property.user.email,
                    property_id: props.property.id,
                    object: props.property
                  }} 
                  onClose={ handleOnCloseSendMailForm }
                />
              </Grid>
          </Grid>
        </Box>
      </Dialog>
      
    </>
  );
}
