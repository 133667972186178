// action - state management
import * as actionTypes from './actions';

// ==============================|| CUSTOMER USER REDUCER ||============================== //

export const initialState = {
  search: {
    country_id: 0,
    state_id: 0,
    city_id: 0,
    neighborhood: "",
    material_id: 0,
    commercialoperation_id: 0,
    m3_min: 0,
    m3_max: 0,
    price_min: 0,
    price_max: 0,
    years_min: 0,
    years_max: 0
  },
  propertyID: 0
};

const currentSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH:
      return {
        ...state,
        search: action.search
      };
    case actionTypes.SET_PROPERTYID:
      return {
        ...state,
        propertyID: action.propertyID
      };
    default:
      return state;
  }
};

export default currentSessionReducer;
