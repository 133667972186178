import React from 'react';

import GetCookies from '../components/GetCookies';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';

const Cookies = () => {
  return (
    <>
      <Navbar />
      <GetCookies />
      <Footer />
    </>
  )
}

export default Cookies