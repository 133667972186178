import React from 'react'

import GetThankYou from '../components/GetThankYou/GetThankYou';

const ThankYou = () => {

  return (
    <>
      <GetThankYou/>
    </>

  )
}

export default ThankYou

