import React, { useEffect, useState } from 'react'
import { 
  Box, 
  Stack, 
  styled, 
  Typography,
} from '@mui/material'
import Link from '@mui/material/Link';
import FooterTitle from './FooterTitle'
import FooterLink from './FooterLink'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

/* LLAMADAS DE SERVICIOS API */
import SiteService from '../../services/SiteService';

import logo from '../../assets/images/buscatrasterologo.png';

const Footer = () => {
  const [parameters, setParameters] = useState([]);

  const StackColumn = styled(Stack) (() => ({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    gap: 8,
    textAlign: 'center',
  }));

  const BoxRow = styled(Box) (({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ededed',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 30,
    }
  }));

  const PARAMETER_Get = async () => {
    try {
      const response = await SiteService.PARAMETER_Get();
      setParameters(response.datos.data);
    } catch (err) {
    const errorMessage = "Error: " + err.message;
    console.log(errorMessage);
    }
  };

  useEffect(() => {
    PARAMETER_Get();
  }, []);

  return (
    
    <BoxRow 
    component = 'thankyou'
    sx={{
      py: 4,
      px: 2,
    }}
    >
      <StackColumn>
        <FooterTitle text={'Dirección'} />
        <FooterLink 
        text={ parameters.address1 } 
        />
        <FooterLink 
        text={ parameters.address2 } 
        />
        <FooterLink 
        text={ parameters.phones } 
        />
        <FooterLink 
        text={ parameters.email } 
        />
      </StackColumn>
      
      <StackColumn>
        <FooterTitle text={'Accesos'} />
        <FooterLink text={'Contáctenos'} direction="/contact"/>
        <FooterLink text={'Preguntas'} direction="/faq"/>
        <FooterLink text={'Políticas'} direction="/politics"/>
        <FooterLink text={'Cookies'} direction="/cookies"/>
      </StackColumn>

      <StackColumn>
        <FooterTitle text={'Busca Trastero'} />
        <Stack 
        direction='row' 
        width= '70px'
        maxWidth='100%'
        justifyContent='space-between'
        >
          <Link href={ parameters.instagram } target="_blank" variant="body2" 
          sx={{
            color: '#414141',
            "&:hover": {
              color: '#1c2859',
            }
          }}
          >
            <InstagramIcon fontSize="large"/>  
          </Link> 
          <Link href={ parameters.facebook } target="_blank" variant="body2" 
          sx={{
            color: '#414141',
            "&:hover": {
              color: '#1c2859',
            }
          }}
          >
            <FacebookIcon fontSize="large"/>
          </Link> 
        </Stack>
        <Typography 
        variant='caption'
        component='p' 
        >
          &copy; 2024 Busca Trastero.
        </Typography>
      </StackColumn>

      <StackColumn>
        <Box>
            <img src={logo} alt="Logo" width="250px" height="250px"/>
        </Box>
      </StackColumn>

    </BoxRow>
  )
}

export default Footer