import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import {
  Grid,
} from '@mui/material';

import { gridSpacing } from '../config';
import { useTheme } from '@mui/material/styles';

//import Carousel from '../components/Carousel';

import Criteria from '../components/Forms/Criteria';
import Result from '../components/Result';
import GetMeta from '../components/GetMeta';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';

const Property = () => {
  const theme = useTheme();
  const [isChangeResult, setIsChangeResult] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />
      <Grid container spacing={gridSpacing} sx={{ p: theme.spacing(2) }}>
        <Grid item sm={3} xs={12}>
          <Criteria isChangeResult = { isChangeResult } setIsChangeResult = { setIsChangeResult } />
        </Grid>
        <Grid item sm={9} xs={12}>
          <Result isChangeResult = { isChangeResult } setIsChangeResult = { setIsChangeResult } />
        </Grid>
      </Grid>
{/*      <Carousel page={"Property"}/> */ }
      <Footer />
      <GetMeta page={2} />
    </>
  )
}

export default Property


/*
      <Breadcrumb divider={false}>
        <Typography component={Link} to="/" variant="h4" color="inherit" className="link-breadcrumb">
          Home
        </Typography>
        <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
          Propiedades
        </Typography>
      </Breadcrumb>    

*/