import React from 'react';

import GetFaq from '../components/GetFaq';
//import Carousel from '../components/Carousel';
import GetMeta from '../components/GetMeta';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer/Footer';

const Faq = () => {
  return (
    <>
      <Navbar />
      <GetFaq />
{/*       <Carousel page={"Faq"}/> */}
      <Footer />
      <GetMeta page={5} />
    </>
  )
}

export default Faq